<template>
  <transition appear enter-active-class="animated fadeIn">
    <div>
      <div class="level-item">
        <h1 class="title is-1 page-title">
          Travel Insurance for Non UK Residents
        </h1>
      </div>
      <div class="box b-shdw-3 mt-5">
        <h3>Travel insurance: simple & flexible</h3>
        <p>
          You can
          <a
            href="https://www.worldnomads.com/Turnstile/AffiliateLink?partnerCode=RU5H1N5UR4NC3&source=link&utm_source=RU5H1N5UR4NC3&utm_content=link&path=https://www.worldnomads.com/travel-insurance/"
            >buy and claim online</a
          >, even after you've left home. Travel insurance from
          <a
            href="//www.worldnomads.com/Turnstile/AffiliateLink?partnerCode=RU5H1N5UR4NC3&source=link&utm_source=RU5H1N5UR4NC3&utm_content=link&path=https://www.worldnomads.com"
            >WorldNomads.com</a
          >
          is available to people from over 130 countries. It’s designed for
          adventurous travellers with cover for overseas medical, evacuation,
          baggage and a range of
          <a
            href="//www.worldnomads.com/Turnstile/AffiliateLink?partnerCode=RU5H1N5UR4NC3&source=link&utm_source=RU5H1N5UR4NC3&utm_content=link&path=https://www.worldnomads.com/adventure-travel-insurance"
            >adventure sports and activities</a
          >.
        </p>
        

        <!-- <div id="wn-insurance-quote-editor"></div> -->

        <br />
        <h3>
          7 things you should know about travel insurance from WorldNomads.com
        </h3>
        <br />
        <ol class="main-list-class">
          <li>
            <b>Trusted reliable underwriters</b><br /><a
              href="//www.worldnomads.com/Turnstile/AffiliateLink?partnerCode=RU5H1N5UR4NC3&source=link&utm_source=RU5H1N5UR4NC3&utm_content=link&path=https://www.worldnomads.com"
              >WorldNomads.com</a
            >
            is backed by a suite of strong, secure, specialist travel insurers
            who provide you with great cover, 24 hour emergency assistance and
            the highest levels of support and claims management when you need it
            most.<br />
          </li>
          <li>
            <b>Value for money with the cover you need</b><br /><a
              href="//www.worldnomads.com/Turnstile/AffiliateLink?partnerCode=RU5H1N5UR4NC3&source=link&utm_source=RU5H1N5UR4NC3&utm_content=link&path=https://www.worldnomads.com"
              >WorldNomads.com</a
            >
            provides cover for what's important for travellers from over 130
            countries. By focusing on what you need and leaving out what you
            don't, World Nomads prices are some of the most competitive
            online.<br />
          </li>
          <li>
            <b>Flexibility when you need it most</b><br />Had a change of plans?
            You can buy more cover or
            <a
              href="//www.worldnomads.com/Turnstile/AffiliateLink?partnerCode=RU5H1N5UR4NC3&source=link&utm_source=RU5H1N5UR4NC3&utm_content=link&path=https://www.worldnomads.com/travel-insurance/travel-insurance-claims"
              >claim online</a
            >
            while you are still away. You can even
            <a
              href="//www.worldnomads.com/Turnstile/AffiliateLink?partnerCode=RU5H1N5UR4NC3&source=link&utm_source=RU5H1N5UR4NC3&utm_content=link&path=https://www.worldnomads.com/travel-insurance/"
              >buy</a
            >
            a World Nomads policy if you're already travelling.<br />
          </li>
          <li>
            <b>Cover for a range of adventure activities</b><br />From skiing &
            snowboarding in New Zealand to whitewater rafting in Colorado,
            WorldNomads.com covers a range of
            <a
              href="//www.worldnomads.com/Turnstile/AffiliateLink?partnerCode=RU5H1N5UR4NC3&source=link&utm_source=RU5H1N5UR4NC3&utm_content=link&path=https://www.worldnomads.com/adventure-travel-insurance"
              >adventure activities</a
            >, giving you peace of mind to get the most from your travels.<br />
          </li>
          <li>
            <b>World Nomads keeps you travelling safely</b><br />All
            WorldNomads.com members have access to up-to-date travel safety
            alerts, as well as travel safety advice and tips online through the
            <a
              href="//www.worldnomads.com/Turnstile/AffiliateLink?partnerCode=RU5H1N5UR4NC3&source=link&utm_source=RU5H1N5UR4NC3&utm_content=link&path=https://www.worldnomads.com/travel-safety"
              >World Nomads Travel Safety Hub</a
            >.<br />
          </li>
          <li>
            <b>More than just great value travel insurance</b><br />All
            WorldNomads.com members can learn the local lingo through a series
            of
            <a
              href="//www.worldnomads.com/Turnstile/AffiliateLink?partnerCode=RU5H1N5UR4NC3&source=link&utm_source=RU5H1N5UR4NC3&utm_content=link&path=https://www.worldnomads.com/language-guides"
              >iPod & iPhone Language Guides</a
            >
            and can stay in touch with family and friends with an
            <a
              href="//www.worldnomads.com/Turnstile/AffiliateLink?partnerCode=RU5H1N5UR4NC3&source=link&utm_source=RU5H1N5UR4NC3&utm_content=link&path=https://www.worldnomads.com/stories"
              >online travel journal</a
            >.<br />
          </li>
          <li>
            <b>Commitment to exceptional customer service</b><br />We want to
            make sure you get the most from WorldNomads.com. You can find out
            more about
            <a
              href="//www.worldnomads.com/Turnstile/AffiliateLink?partnerCode=RU5H1N5UR4NC3&source=link&utm_source=RU5H1N5UR4NC3&utm_content=link&path=https://www.worldnomads.com/is-travel-insurance-worth-it"
              >why travel insurance is important</a
            >
            for your trip. If you have any questions about your travel insurance
            or travel safety in general, please
            <a
              href="//www.worldnomads.com/Turnstile/AffiliateLink?partnerCode=RU5H1N5UR4NC3&source=link&utm_source=RU5H1N5UR4NC3&utm_content=link&path=https://www.worldnomads.com/contact-us"
              >contact WorldNomads.com</a
            >
            directly.<br />
          </li>
        </ol>
        <small
          >All of the information provided about travel insurance is a brief
          summary only. It does not include all terms, conditions, limitations,
          exclusions and termination provisions of the travel insurance plans
          described. Coverage may not be available for residents of all
          countries, states or provinces. Please carefully read your policy
          wording for a full description of coverage.
        </small>

        <br /><br />

        <div class="is-fullwidth text-center">
          <a
              class="button is-primary hvr-glow"
              href="//www.worldnomads.com/Turnstile/AffiliateLink?partnerCode=RU5H1N5UR4NC3&source=link&utm_source=RU5H1N5UR4NC3&utm_content=link&path=https://www.worldnomads.com/travel-insurance/"
          >
            <strong>CLICK HERE TO GET A QUOTE</strong>
          </a>
        </div>


        <br />

        <!--<div
          id="myQqc"
          data-brand="wn"
          data-partner-code="RU5H1N5UR4NC3"
          data-theme="default"
          data-brand-color="#F58F30"
          data-logo-url="https://i.ibb.co/mqNpvGp/Covered2go-Final-Logo.png"
          data-lang="en"
          data-heading="ver2"
          data-cta="GET A QUOTE"
          data-qqc
        ></div>-->

        <br />

        <InfoButtonOptions :policyType="'ST'" :coverType="'ST'" />
        <info-boxes />
        <!-- <trustpilot-banner /> -->
      </div>
    </div>
  </transition>
</template>
<script>
import InfoBoxes from '../components/common/InfoBoxes';
// import TrustpilotBanner from '@/components/common/trustpilot/TrustpilotBanner';
import InfoButtonOptions from '@/components/common/InfoButtonOptions';

export default {
  name: 'TravelByDestination',
  data() {
    return {};
  },
  methods: {},
  created() {},
  components: {
    InfoBoxes,
    // TrustpilotBanner,
    InfoButtonOptions,
  },
};
</script>

<style lang="scss" scoped>
.main-page-title {
  color: $c2g_orange;
  font-size: 130%;
  text-decoration: none;
  text-align: left;
  padding-bottom: 15px;
  font-weight: bold;
}
.paragraph-text {
  color: black;
  font-size: 100%;
  padding-bottom: 15px;
}
.main-list-class {
  padding-left: 25px;
  padding-bottom: 25px;
}
.list-item {
  list-style-type: disc;
  color: black;
}
.blue-text {
  margin-top: 10px;
  text-align: left;
  color: $c2g_blue;
  font-weight: bold;
  font-size: 115%;
  padding-bottom: 15px;
}
.bold-text {
  font-weight: bold;
}
.text-link {
  color: $c2g_orange;
  text-decoration: underline;
}
.extra-padding-bottom {
  padding-bottom: 10px;
}
.extra-padding-top {
  padding-top: 10px;
}
.underline {
  text-decoration: underline;
}
</style>
